/* eslint-disable:space-in-parens */
/* istanbul ignore file */

import { Route } from '../../shared/routes/Route';
import { RoutePathPattern } from '../../shared/routes/RoutePathPattern';
import { replaceableProductDetailPaths, ReplaceProductDetailPath } from '../../shared/routes/ReplaceProductDetailPath';

export const routes: Route[] = [
  new Route(() => import(/* webpackChunkName: 'Dashboard' */ '../../modules/dashboard/Dashboard'), {
    key: 'dashboard',
    path: RoutePathPattern.DASHBOARD,
    head: {
      title: 'head.title.dashboard',
    },
  }),
  new Route(() => import(/* webpackChunkName: 'AppCookieConsent' */ '../../modules/cookieConsent/AppCookieConsent'), {
    key: 'appCookieConsent',
    path: RoutePathPattern.APP_COOKIE_CONSENT,
  }),
  new Route(() => import(/* webpackChunkName: 'Positioning' */ '../../modules/positioning/Positioning'), {
    key: 'positioning',
    path: RoutePathPattern.POSITIONING,
  }),
  new Route(
    () =>
      import(
        /* webpackChunkName: 'ManagePositioningPermissions' */ '../../modules/positioning/managePositioningPermissions/ManagePositioningPermissions'
      ),
    {
      key: 'managePositioningPermissions',
      path: RoutePathPattern.MANAGE_POSITIONING_PERMISSIONS,
    }
  ),
  new Route(() => import(/* webpackChunkName: 'PositioningTermsBasic' */ '../../modules/positioning/PositioningTermsBasic'), {
    key: 'positioningTerms',
    path: RoutePathPattern.POSITIONING_TERMS,
  }),
  new Route(
    () =>
      import(
        /* webpackChunkName: 'FinanceAgreementTermination' */ '../../modules/finance/agreementTermination/FinanceAgreementTerminationView'
      ),
    {
      key: 'financeAgreementTerminationPage',
      path: RoutePathPattern.FINANCE_AGREEMENT_TERMINATION,
    }
  ),
  /**
   * Route with path "/teenused/:productIdAndOrigin" (PRODUCT_DETAILS) must be defined after
   * other routes with matching paths (in the form of "/teenused/<reserved-word>")
   */
  new Route(() => import(/* webpackChunkName: 'Products' */ '../../modules/products/Products'), {
    key: 'products',
    path: [
      RoutePathPattern.HOME_PRODUCTS,
      RoutePathPattern.OFFICE_PRODUCTS,
      RoutePathPattern.VAS_PRODUCTS,
      RoutePathPattern.MOBILE_PRODUCTS,
      RoutePathPattern.TV_PRODUCTS,
      RoutePathPattern.INTERNET_PRODUCTS,
      RoutePathPattern.USER_RENTAL_DEVICES,
      RoutePathPattern.CANCEL_CHANGE_CUSTOMER,
    ],
  }),

  new Route(() => import(/* webpackChunkName: 'ProductDetailsRouter' */ '../../modules/products/ProductDetailsRouter'), {
    key: 'productDetailsRouter',
    path: [RoutePathPattern.PRODUCT_DETAILS],
  }),
  /**
   * Redirect "/teenused/(mobiil|kodu|kontor|lisateenused)/:productIdAndOrigin" -> "/teenused/:productIdAndOrigin"
   * for backwards compatibility in "Minu Telia" app services webview
   *
   * @deprecated
   */
  new Route(() => Promise.resolve({ default: ReplaceProductDetailPath }), {
    key: 'replaceProductDetailPath',
    path: replaceableProductDetailPaths,
  }),
  new Route(() => import(/* webpackChunkName: 'Agreements' */ '../../modules/agreements/Agreements'), {
    key: 'agreements',
    path: RoutePathPattern.AGREEMENTS,
  }),
  new Route(() => import(/* webpackChunkName: 'ServiceAgreements' */ '../../modules/agreements/serviceAgreements/ServiceAgreements'), {
    key: 'serviceAgreements',
    path: RoutePathPattern.SERVICE_AGREEMENTS,
  }),
  new Route(() => import(/* webpackChunkName: 'MobileSelection' */ '../../modules/productSelection/ProductSelection'), {
    key: 'productSelection',
    path: [RoutePathPattern.MOBILE_SELECTION, RoutePathPattern.PRODUCT_SELECTION],
  }),
  new Route(() => import(/* webpackChunkName: 'MobileSelection' */ '../../modules/numberPairing/NumberPairing'), {
    key: 'numberPairing',
    path: RoutePathPattern.NUMBER_PAIRING,
  }),
  new Route(() => import(/* webpackChunkName: 'ProductSelectTest' */ '../../modules/products/details/ProductDetailTestView'), {
    key: 'productDetailsTestPage',
    path: RoutePathPattern.DETAILS_VIEW_TEST,
  }),
  new Route(() => import(/* webpackChunkName: 'ProductSelectTest' */ '../../modules/products/list/AllProductListView'), {
    key: 'allProductsList',
    path: RoutePathPattern.ALL_SERVICES,
  }),
  new Route(() => import(/* webpackChunkName: 'ProductSelectTest' */ '../../modules/products/list/FinanceProductListView'), {
    key: 'financeProductsList',
    path: RoutePathPattern.FINANCE_PRODUCTS,
  }),
  new Route(
    () => import(/* webpackChunkName: 'OldProductSelect' */ '../../modules/products/components/productDetailView/OldProductDetailView'),
    {
      key: 'oldProductDetailsPage',
      path: RoutePathPattern.DETAILS_VIEW_OLD,
    }
  ),
];
