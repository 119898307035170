/* istanbul ignore file */

import { IFormHelperProps, INoticeProps } from 'telia-front-react';
interface Options<T extends IFormHelperProps | INoticeProps = INoticeProps> {
  [key: string]: any;
  type?: T['type'];
}

export class Message<T extends IFormHelperProps | INoticeProps = INoticeProps> {
  key?: string;
  render: () => React.ReactNode;
  options?: Options<T>;

  constructor(data?: Partial<Message<T>>) {
    Object.assign(this, data);
  }

  get type(): Options<T>['type'] | undefined {
    return (this.options && this.options.type) || undefined;
  }
}
