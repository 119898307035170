/* istanbul ignore file */

import { action, computed, observable, makeObservable } from 'mobx';
import { readFeatureFlags } from '../../service/Api';
import { ErrorUtil } from '../../util/ErrorUtil';
import { FeatureFlag } from '../../model/FeatureFlag';

export default class FeatureStore {
  static SHOW_ACTIONS_FOR_ADDITIONAL_SIM = 'SHOW_ACTIONS_FOR_ADDITIONAL_SIM';
  static ENABLE_DELETE_ARGOS_FEATURE = 'ENABLE_DELETE_ARGOS_FEATURE';
  static ENABLE_DELETE_PRODUCT_FORM_FOR_B2B_INTERNET = 'ENABLE_DELETE_PRODUCT_FORM_FOR_B2B_INTERNET';
  static ENABLE_XMAS_OFFERS = 'ENABLE_XMAS_OFFERS';
  static ENABLE_NEW_DETAIL_VIEW = 'ENABLE_NEW_DETAIL_VIEW';
  static ENABLE_NEW_DETAIL_VIEW_TEST_PAGE = 'ENABLE_NEW_DETAIL_VIEW_TEST_PAGE';
  static ENABLE_AGREEMENTS_KM_NOTIFICATION = 'ENABLE_AGREEMENTS_KM_NOTIFICATION';
  static ENABLE_NEW_PRODUCT_LIST_VIEW = 'ENABLE_NEW_PRODUCT_LIST_VIEW';
  static ENABLE_NEW_FINANCE_VIEW = 'ENABLE_NEW_FINANCE_VIEW';
  static ENABLE_NEW_FINANCE_VIEW_FROM_AGREEMENTS = 'ENABLE_NEW_FINANCE_VIEW_FROM_AGREEMENTS';
  static ENABLE_VOIP_FOR_PRIVATE_CUSTOMER = 'ENABLE_VOIP_FOR_PRIVATE_CUSTOMER';

  @observable private readonly featureFlags: Map<string, boolean> = new Map();
  @observable initialized = false;

  constructor() {
    makeObservable(this);
  }

  async init(onError?: (error: any) => void) {
    await this.initFeatureFlags(onError);
    this.setInitialized();
  }

  isEnabled(feature: string): boolean {
    return this.getFeatureFlag(feature) || false;
  }

  private getFeatureFlag(feature: string): boolean | undefined {
    if (!this.initialized) {
      ErrorUtil.pushError(new Error('Illegal state, FeatureStore should be initialized before Business Logic Stores'));
    }
    return this.featureFlags.get(feature);
  }

  @action
  private setInitialized = () => {
    this.initialized = true;
  };

  @action
  private setFeatureFlags = (featureFlags: FeatureFlag[]) => {
    featureFlags.forEach(({ feature, enabled }) => this.featureFlags.set(feature, enabled));
  };

  private async initFeatureFlags(onError?: (error: any) => void) {
    try {
      const { responsePromise } = readFeatureFlags();
      const featureFlags = (await responsePromise).data;
      this.setFeatureFlags(featureFlags || []);
    } catch (error: any) {
      if (onError) {
        error.message = 'Failed to fetch feature flags: ' + error.message;
        onError(error);
      }
    }
  }

  @computed
  get showActionsForAdditionalSim(): boolean {
    return this.isEnabled(FeatureStore.SHOW_ACTIONS_FOR_ADDITIONAL_SIM);
  }

  @computed
  get enableArgosDelete(): boolean {
    return this.isEnabled(FeatureStore.ENABLE_DELETE_ARGOS_FEATURE);
  }

  @computed
  get enableDeleteProductFormForB2bInternet(): boolean {
    return this.isEnabled(FeatureStore.ENABLE_DELETE_PRODUCT_FORM_FOR_B2B_INTERNET);
  }

  @computed
  get enableXmasOffers(): boolean {
    return this.isEnabled(FeatureStore.ENABLE_XMAS_OFFERS);
  }

  @computed
  get enableAgreementsKmNotification(): boolean {
    return this.isEnabled(FeatureStore.ENABLE_AGREEMENTS_KM_NOTIFICATION);
  }

  @computed
  get enableNewFinanceViewFromAgreements(): boolean {
    return this.isEnabled(FeatureStore.ENABLE_NEW_FINANCE_VIEW_FROM_AGREEMENTS);
  }

  @computed
  get enableVoipForPrivateCustomer(): boolean {
    return this.isEnabled(FeatureStore.ENABLE_VOIP_FOR_PRIVATE_CUSTOMER);
  }
}
