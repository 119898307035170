import Room from '../../store/address/Room';

export default class Address {
  id: string;
  label: string;
  label2?: string;
  region: string;
  street: string;
  buildingLabel?: string;
  apartment: null | string;
  cityCounty: string;
  house: string;
  postalCode: string;
  addressType: string;
  addInformation: string;
  municipality: string;
  buildingId: string;
  buildingName: string;
  buildingType: string;
  buildingCoordX: string;
  buildingCoordY: string;

  shortLabel?: string;

  rooms?: Room[];
}
