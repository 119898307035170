import React from 'react';
import { inject, observer } from 'mobx-react';
import SpinnerStore from './SpinnerStore';
import { Loadable } from '../../util/Loadable';

interface SpinnerToggleProps {
  name: string;
  loadable?: Loadable<any>;
  children: React.ReactChild | React.ReactChild[];
  spinnerStore?: SpinnerStore;
}

@inject('spinnerStore')
@observer
export default class SpinnerToggle extends React.Component<SpinnerToggleProps> {
  render() {
    const { name, spinnerStore, children, loadable } = this.props;

    if ((loadable && loadable.isLoading) || spinnerStore!.isVisible(name)) {
      return children;
    }

    return null;
  }
}
