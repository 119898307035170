/* istanbul ignore next */
import { ApiRequest, ApiRequestConfig } from './ApiRequest';
import { ITemplateSalesTotalServiceCharge, ITemplateSalesTotalServiceChargeServiceUsages } from 'telia-front-react';
import uriTemplate from 'uri-templates';
import { ApiUrl } from '../model/ApiUrl';
import { MobileProductUserResource } from '../model/product/mobileProductUser/MobileProductUser';
import { ProductUserUpdateStatus } from '../model/product/productUserUpdateStatus/ProductUserUpdateStatus';
import AcknowledgedOrder from '../model/acknowledgedOrder/AcknowledgedOrder';
import CustomerAccess from '../model/customer/customerAccess/CustomerAccess';
import { AgreementsHolderResource } from '../model/agreement/AgreementsHolderResource';
import EvaluatedPromotion from '../model/promotion/EvaluatedPromotion';
import { ProductByAgreementHolderResource } from '../model/agreement/ProductByAgreementHolderResource';
import { TransferredCustomer } from '../model/customer/transferredCustomer/TransferredCustomer';
import { QuickLink } from '../model/quickLink/QuickLink';
import { HtmlDocument, HtmlDocumentResource } from '../model/htmlDocument/HtmlDocument';
import { ProductUserCompactResource } from '../model/product/productUserCompact/ProductUserCompact';
import { GroupedOverview } from '../model/groupedOverview/GroupedOverview';
import AcknowledgedOrderCount from '../model/acknowledgedOrder/AcknowledgedOrderCount';
import { PositioningLocation } from '../model/positioning/PositioningLocation';
import { PositioningPermission, PositioningPermissionStatus } from '../model/positioning/PositioningPermission';
import { BillingOverviewResource } from '../model/billingOverview/BillingOverviewResource';
import { ShowNewDetailsConfig } from '../model/config/Config';
import { NotificationResponse } from '../model/notification/NotificationResponse';
import { QueryProductRecommendationResource } from '../model/recommendation/QueryProductRecommendationResource';
import { MarketSegmentInstanceResponse } from '../model/customerMarketSegmentInstance/MarketSegmentInstanceResponse';
import { AgreementResource } from '../model/agreement/AgreementResource';
import { TermsResource } from '../model/terms/TermsResource';
import {
  FinanceAgreementTerminationPageDataParams,
  FinanceAgreementTerminationData,
} from '../../modules/finance/agreementTermination/components/FinanceAgreementTermination';
import { ProductDetailsData } from '../model/product/common/productDetailsData/ProductDetailsData';
import { ProductOverviewData } from '../model/product/details/overview/ProductOverviewData';
import { ProductListPageData } from '../model/product/list/ProductListPageData';
import { ProductListsRequestParams } from '../model/product/list/ProductListsRequestParams';
import { ListsData } from '../model/product/list/ListsData';
import { ProductConfigRequestParams } from '../model/product/ProductConfigRequestParams';
import { NoticesData } from '../model/product/common/productDetailsData/NoticesData';
import { PaymentPlanData, PaymentPlanParams } from '../model/finance/paymentPlan/PaymentPlanData';
import { FilterRefNumsData } from '../model/product/common/filter/FilterRefNumsData';
import { DetailsModalData } from '../model/product/common/modal/DetailsModalData';
import { ProductLocationData } from '../model/product/common/filter/ProductLocationData';
import { ApiResponse, ApiResponseWithError } from '../model/api/ApiResponse';
import { API, catchToArray, isApiResponseWithError, makeRequest } from '../util/apiUtil';
import { ApiRequest as coreApiRequest } from '../model/api/ApiRequest';
import { CatalogCode } from '../model/CatalogCode';
import { CategoryCode } from '../model/CategoryCode';
import { Origin } from '../model/Origin';
import { Locale } from '../model/config/Locale';
import { FeatureFlag } from '../model/FeatureFlag';
import { ProductSpecIdTvChannelsMap } from '../model/tv/TvChannel';
import { QuickLinkOverview } from '../model/QuickLinkOverview';
import { ProductDetailsResource } from '../model/ProductDetailsResource';
import { OrderStatusResource } from '../components/OrderStatus';
import { CompactProductSummariesResource } from '../model/CompactProductSummaryResource';
import { CustomerProductsHolderResource } from '../model/CustomerProductsHolder';
import { ProductSearchConstraints } from '../model/ProductSearchConstraints';
import { PresaleQuery } from '../model/PresaleQuery';
import { TranslationsResponse } from '../model/translation/Translations';
import { PrivacyNotice } from '../model/PrivacyNotice';

export const transferCustomer = () => makeRequest<TransferredCustomer>({ url: ApiUrl.DASHBOARD_TRANSFER_CUSTOMER });
export const readQuickLinks = () => makeRequest<QuickLink[]>({ url: ApiUrl.DASHBOARD_QUICK_LINKS });
export const getHtmlDocument = (url: string) =>
  makeRequest<HtmlDocument>({
    url,
    transformResponse: (responseBody: HtmlDocumentResource | ApiResponseWithError): ApiResponse<HtmlDocument> =>
      isApiResponseWithError(responseBody) ? responseBody : { data: new HtmlDocument(responseBody) },
  });

export const writeCustomSolutionAgreementNumber = (productId: number, agreementNumber: string) =>
  makeRequest<void>({
    method: 'patch',
    headers: {}, // headers without Accept: application/json because we are expecting 204 "No Content"
    url: uriTemplate(ApiUrl.UPDATE_CUSTOM_SOLUTION_AGREEMENT_NUMBER).fill({ productId: productId.toString() }),
    data: { agreementNumber },
  });

export const readDashboardGroupedOverviews = ({
  customerId,
  groupingType,
  countryCode,
  personalCode,
}: {
  customerId: number;
  groupingType: string;
  countryCode?: string;
  personalCode?: string;
}) =>
  makeRequest<GroupedOverview[]>({
    url: uriTemplate(ApiUrl.DASHBOARD_GROUPED_OVERVIEWS).fill({ groupingType }),
    params: { customerId, countryCode, personalCode },
  });

export const readPositioning = (msisdns: string[]): coreApiRequest<PositioningLocation[]> =>
  makeRequest({
    method: 'post',
    url: ApiUrl.POSITIONING_LOCATION,
    data: {
      msisdns,
    },
  });

export const getTotalServiceChargeData = (
  productId: string,
  origin: Origin,
  locale: Locale
): coreApiRequest<ITemplateSalesTotalServiceCharge> =>
  makeRequest({
    url: uriTemplate(ApiUrl.TOTAL_SERVICE_CHARGE).fill({ productId }),
    params: { origin, locale },
  });

export const readPositioningPermissionsForMe = (...status: PositioningPermissionStatus[]): coreApiRequest<PositioningPermission[]> =>
  makeRequest({
    url: ApiUrl.POSITIONING_PERMISSIONS_FOR_ME,
    params: { status },
  });

export const readPositioningPermissionsForOthers = (...status: PositioningPermissionStatus[]): coreApiRequest<PositioningPermission[]> =>
  makeRequest({
    url: ApiUrl.POSITIONING_PERMISSIONS_FOR_OTHERS,
    params: { status },
  });

export const deletePositioningPermission = (id: string): coreApiRequest<void> =>
  makeRequest({
    method: 'delete',
    url: uriTemplate(ApiUrl.POSITIONING_PERMISSIONS_DELETE).fill({ id }),
  });

export const acceptPositioningPermission = (id: string): coreApiRequest<void> =>
  makeRequest({
    method: 'post',
    url: uriTemplate(ApiUrl.POSITIONING_PERMISSIONS_ACCEPT).fill({ id }),
  });

export const askPositioningPermission = (phoneNumber: string) =>
  makeRequest<boolean>({
    method: 'post',
    url: uriTemplate(ApiUrl.POSITIONING_PERMISSIONS_ASK).fill({}),
    data: {
      phoneNumber,
    },
  });

export const readBillingOverview = (): coreApiRequest<BillingOverviewResource> =>
  makeRequest<BillingOverviewResource>({
    url: ApiUrl.BILLING_OVERVIEW,
  });

export const getTotalServiceUsagesData = (
  source: string,
  usageRefNumber: string,
  accountId: number,
  commDeviceNumber: string,
  locale: Locale,
  serviceId?: number
): coreApiRequest<ITemplateSalesTotalServiceChargeServiceUsages> =>
  makeRequest({
    url: uriTemplate(ApiUrl.TOTAL_SERVICE_CHARGE_USAGES).fill({ usageRefNumber }),
    params: {
      locale,
      accountId,
      source,
      commDeviceNumber,
      serviceId,
    },
  });

export const readNotifications = () =>
  makeRequest<NotificationResponse[]>({
    method: 'get',
    url: ApiUrl.DASHBOARD_NOTIFICATIONS,
  });

export const clickNotification = (id: string) =>
  makeRequest<NotificationResponse[]>({
    method: 'put',
    url: uriTemplate(ApiUrl.DASHBOARD_NOTIFICATIONS_CLICK).fill({ id }),
  });

export const fetchCustomerMarketSegmentInstances = () =>
  makeRequest<MarketSegmentInstanceResponse[]>({
    url: ApiUrl.DASHBOARD_CUSTOMER_MARKET_SEGMENT_INSTANCES_V2,
  });

export const fetchShowNewDetails = (segmentType: string) =>
  makeRequest<ShowNewDetailsConfig>({
    url: ApiUrl.SHOW_NEW_DETAILS,
    params: { segmentType },
  });

export const loadProductData = (globalProductId: string, type: string, params: ProductConfigRequestParams) =>
  makeRequest<ProductDetailsData>({
    url: uriTemplate(ApiUrl.PRODUCT_DETAILS_DATA).fill({ globalProductId, type }),
    params,
  });

export const loadFilterRefnumsData = () =>
  makeRequest<FilterRefNumsData[]>({
    url: ApiUrl.LIST_FILTER_REFNUMS_DATA,
  });

export const loadProductLocations = (addressString: string) =>
  makeRequest<ProductLocationData[]>({
    url: ApiUrl.PRODUCT_ADDRESS_SEARCH,
    params: { address: addressString },
  });

export const loadProductOverviewData = (globalProductId: string, params: ProductConfigRequestParams) =>
  makeRequest<ProductOverviewData>({
    url: uriTemplate(ApiUrl.PRODUCT_DETAILS_OVERVIEW_DATA).fill({ globalProductId }),
    params,
  });

export const loadProductNoticesData = (globalProductId: string, params: ProductConfigRequestParams) =>
  makeRequest<NoticesData>({
    url: uriTemplate(ApiUrl.PRODUCT_DETAILS_NOTICES_DATA).fill({ globalProductId }),
    params,
  });

export const loadProductDetailsModalData = (globalProductId: string, params: ProductConfigRequestParams) =>
  makeRequest<DetailsModalData>({
    url: uriTemplate(ApiUrl.PRODUCT_DETAILS_MODAL_DATA).fill({ globalProductId }),
    params,
  });

export const loadProductsLists = (params: ProductConfigRequestParams) =>
  makeRequest<ListsData>({
    url: ApiUrl.PRODUCTS_LISTS,
    params,
  });

export const loadProductLists = (listName: string, params: ProductListsRequestParams) =>
  makeRequest<ProductListPageData>({
    url: uriTemplate(ApiUrl.PRODUCT_LISTS).fill({ listName }),
    params,
  });

export const readFeatureFlags = () =>
  makeRequest<FeatureFlag[]>({
    method: 'get',
    url: ApiUrl.FEATURE_FLAGS,
    transformResponse: (responseBody) => (isApiResponseWithError(responseBody) ? responseBody : { data: responseBody }),
  });

export const readTvChannelsByProductSpec = (productSpecIds: number[], lang: string) =>
  makeRequest<ProductSpecIdTvChannelsMap>({
    url: ApiUrl.TV_CHANNELS,
    params: { productSpecIds, lang },
  });

export const acceptTerms = (termsResource: TermsResource) =>
  makeRequest<void>({
    url: ApiUrl.TERMS_ACCEPT,
    headers: {},
    data: termsResource,
    method: 'post',
  });

export const terminateFinanceAgreement = (productGlobalId: string) =>
  makeRequest<void>({
    url: uriTemplate(ApiUrl.TERMINATE_FINANCE_AGREEMENT).fill({ productGlobalId }),
    method: 'patch',
  });

export const loadFinanceAgreementTerminationPageData = (
  globalProductId: string,
  type: string,
  params: FinanceAgreementTerminationPageDataParams
) =>
  makeRequest<FinanceAgreementTerminationData>({
    url: uriTemplate(ApiUrl.FINANCE_AGREEMENT_TERMINATION_CONTENT).fill({ globalProductId, type }),
    params,
  });

export const loadPaymentPlanData = (globalProductId: string, params: PaymentPlanParams) =>
  makeRequest<PaymentPlanData>({
    url: uriTemplate(ApiUrl.PAYMENT_PLAN).fill({ globalProductId }),
    params,
  });

export const readTermsConsent = (type: string, version: string) =>
  makeRequest<boolean>({
    url: ApiUrl.TERMS_CONSENT,
    params: { type, version },
  });

export const createBasket = async (customerId: number) =>
  catchToArray(API.post<string>(ApiUrl.CREATE_BASKET, { customerId }).then((response) => response.data));

export const fetchProductOfferings = async (
  customerId: number,
  basketId: string,
  productCatalogCode: CatalogCode,
  productCategoryCodes: CategoryCode[]
) =>
  catchToArray(
    API.get<Array<{ uid: string }>>(uriTemplate(ApiUrl.CUSTOMER_PRODUCT_OFFERINGS).fill({ customerId: customerId.toString() }), {
      params: {
        basketId,
        productCatalogCode,
        productCategoryCodes,
      },
    }).then((response) => response.data)
  );

export const readQuickLinksOverview = () =>
  makeRequest<QuickLinkOverview[]>({
    url: ApiUrl.DASHBOARD_QUICK_LINKS_OVERVIEW,
  });

export const readProductSummaries = (searchConstraints: ProductSearchConstraints, size: number, page?: number) =>
  makeRequest<CustomerProductsHolderResource>({
    url: ApiUrl.PRODUCT_SUMMARIES,
    params: {
      searchConstraints: JSON.stringify(searchConstraints),
      page,
      size,
    },
    transformResponse: (
      responseBody: CustomerProductsHolderResource | ApiResponseWithError
    ): ApiResponse<CustomerProductsHolderResource> => {
      if (isApiResponseWithError(responseBody)) {
        return {
          errors: responseBody.errors,
        };
      }
      return {
        data: {
          ...responseBody,
          products: responseBody.products.map((product) => ({
            ...product,
            catalogCode: searchConstraints.productCatalogCode,
          })),
        },
      };
    },
    /**
     * @todo MYSE-2697 transformResponse should be the following if response JSON follows JSON-API standard.
     */
    // transformResponse: ({ data, ...rest }: ApiResponse<CustomerProductsHolderResource>) => ({
    //   ...rest,
    //   data: data && {
    //     ...data,
    //     products: data.products.map((product) => ({
    //       ...product,
    //       catalogCode: searchConstraints.productCatalogCode,
    //     })),
    //   },
    // }),
    /**
     * @todo MYSE-1992 Catalog code should be included in the backend already
     */
  });

export const sendToPresale = (data: PresaleQuery) =>
  makeRequest<void>({
    method: 'post',
    url: ApiUrl.CUSTOMER_PRESALE_API,
    data,
  });

export const fetchTranslations = async (lang: string) => {
  const response = await API.request<TranslationsResponse>({ url: `${ApiUrl.TRANSLATIONS}/${lang}` });
  return response.data;
};

export const changeCustomer = async (customerId: number) => {
  const response = await API.post<void>(`/sales-api/sso/select/customer/${customerId}`);
  return response.data;
};

export const readPrivacyNotice = () =>
  makeRequest<PrivacyNotice>({
    method: 'get',
    url: ApiUrl.DASHBOARD_PRIVACY_NOTICE,
  });

export class Api {
  static productDetails(config?: ApiRequestConfig) {
    return new ApiRequest<ProductDetailsResource>(ApiUrl.PRODUCT_DETAILS, config);
  }

  static productUsers(config?: ApiRequestConfig) {
    return new ApiRequest<ProductUserCompactResource[]>(ApiUrl.PRODUCT_USERS, config);
  }

  static productUserByProductId(config?: ApiRequestConfig) {
    return new ApiRequest<MobileProductUserResource>(ApiUrl.PRODUCT_USER_BY_PRODUCT_ID, config);
  }

  static productUserUpdateStatus(config?: ApiRequestConfig) {
    return new ApiRequest<ProductUserUpdateStatus>(ApiUrl.PRODUCT_USER_UPDATE_STATUS, config);
  }

  static productTotalServiceCharge(config?: ApiRequestConfig) {
    return new ApiRequest<ITemplateSalesTotalServiceCharge | undefined>(ApiUrl.TOTAL_SERVICE_CHARGE, config);
  }

  static orderStatuses(config?: ApiRequestConfig) {
    return new ApiRequest<OrderStatusResource[]>(ApiUrl.ORDER_STATUSES, config);
  }

  static mobileGroupName(config?: ApiRequestConfig) {
    return new ApiRequest<number>(ApiUrl.CHANGE_MOBILE_GROUP_NAME, config);
  }

  static productUser(config?: ApiRequestConfig) {
    return new ApiRequest<ProductUserUpdateStatus>(ApiUrl.PRODUCT_USER, config);
  }

  static indexedProducts(config?: ApiRequestConfig) {
    return new ApiRequest<CompactProductSummariesResource>(ApiUrl.INDEXED_PRODUCTS, config);
  }

  static acknowledgedOrders(config?: ApiRequestConfig) {
    return new ApiRequest<AcknowledgedOrderCount>(ApiUrl.ACKNOWLEDGED_ORDERS, config);
  }

  static updateAlias(config?: ApiRequestConfig) {
    return new ApiRequest<AcknowledgedOrder[]>(ApiUrl.UPDATE_ALIAS, config);
  }

  static customerAccess(config?: ApiRequestConfig) {
    return new ApiRequest<CustomerAccess>(ApiUrl.CUSTOMER_ACCESS, config);
  }

  static agreements(config?: ApiRequestConfig) {
    return new ApiRequest<AgreementsHolderResource>(ApiUrl.AGREEMENTS, config);
  }

  static productAgreements(config?: ApiRequestConfig) {
    return new ApiRequest<AgreementResource[]>(ApiUrl.AGREEMENTS_BY_AGREEMENT_ITEM_IDS, config);
  }

  static evaluatePromotions(config?: ApiRequestConfig) {
    return new ApiRequest<EvaluatedPromotion[]>(ApiUrl.EVALUATE_PROMOTIONS, config);
  }

  static productByAgreement(config?: ApiRequestConfig) {
    return new ApiRequest<ProductByAgreementHolderResource>(ApiUrl.PRODUCT_BY_AGREEMENT, config);
  }

  static calculateQueryProductRecommendation(config?: ApiRequestConfig) {
    return new ApiRequest<QueryProductRecommendationResource>(ApiUrl.QUERY_PRODUCT_RECOMMENDATIONS, config);
  }
}
