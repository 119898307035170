import { action, computed, makeObservable, observable } from 'mobx';

import CrmApi, { CustomerCharacteristic } from '../../service/crm/CrmApi';
import { Loadable } from '../../util/Loadable';
import SsoStore from '../sso/SsoStore';
import ErrorStore from '../error/ErrorStore';
import { Person } from '../../model/crm/Person';

export interface Contact extends Person {
  phoneNumber: string;
  email?: string;
}

export interface PersonConsents {
  directMarketingAllowed?: boolean | null;
  trafficDataAnalysisAllowed?: boolean | null;
  locationDataAnalysisAllowed?: boolean | null;
}

export default class CrmStore {
  ANONYMOUS_CONTACT: Contact = { firstName: '', lastName: '', personalCode: '', phoneNumber: '', email: '' };

  private ssoStore: SsoStore;
  private errorStore: ErrorStore;
  @observable readonly loadableContact = new Loadable<Contact>();
  @observable readonly loadablePerson = new Loadable<Person>();
  @observable readonly loadableCustomerCharacteristics = new Loadable<CustomerCharacteristic[]>();

  constructor(rootStore: { ssoStore: SsoStore; errorStore: ErrorStore }) {
    this.ssoStore = rootStore.ssoStore;
    this.errorStore = rootStore.errorStore;

    makeObservable(this);
  }

  fetchLoggedInPerson = async () =>
    this.loadablePerson.load(() =>
      CrmApi.getPerson(this.ssoStore.customer!.registrationNumber, this.ssoStore.customer!.registrationNumberCountryCode)
    );

  fetchCustomerCharacteristics = async () =>
    this.loadableCustomerCharacteristics.load(
      async () => (await CrmApi.getCustomerCharacteristics(this.ssoStore.customer!.customerId)).customerCharacteristics
    );

  setupContactInfo = async (): Promise<void> => {
    await this.loadableContact.load(() => CrmApi.getContactInfo());
    if (this.loadableContact.isFailed) {
      this.loadableContact.error!.message = 'Contact info fetch failed';
      this.errorStore.setGeneralTechnicalError(this.loadableContact.error);
    }
  };

  @action
  reset() {
    this.loadableContact.reset();
    this.loadablePerson.reset();
    this.loadableCustomerCharacteristics.reset();
  }

  @computed
  get consents(): PersonConsents | undefined {
    return this.loadablePerson.isLoaded
      ? {
        directMarketingAllowed: this.loadablePerson.data!.directMarketingAllowed,
        trafficDataAnalysisAllowed: this.loadablePerson.data!.trafficDataAnalysisAllowed,
        locationDataAnalysisAllowed: this.loadablePerson.data!.locationDataAnalysisAllowed,
      }
      : undefined;
  }

  @computed
  get contact() {
    return this.loadableContact.isLoaded ? this.loadableContact.data : this.ANONYMOUS_CONTACT;
  }

  @computed
  get customerCharacteristicLoaded(): boolean {
    return this.loadableCustomerCharacteristics.isLoaded;
  }

  get isEmployee(): boolean {
    return this.customerCharacteristicContains('EMPLOYEE');
  }

  private customerCharacteristicContains(characteristicName: 'EMPLOYEE') {
    if (!this.customerCharacteristicLoaded || !this.loadableCustomerCharacteristics.data) {
      return false;
    }
    return this.loadableCustomerCharacteristics.data.some(({ name }: CustomerCharacteristic) => name === characteristicName);
  }

  @computed
  get hasCustomerData(): boolean {
    return !!this.loadableContact.data;
  }
}
