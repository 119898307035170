import { useState } from 'react';
import { UiBlocking } from '../UiBlocking';

export const useUiBlocking = (): UiBlocking => {
  const [keysOfUiBlockingTasks, setKeysOfUiBlockingTasks] = useState<string[]>([]);

  return {
    isBlocked: keysOfUiBlockingTasks.length > 0,
    block(newKey: string) {
      setKeysOfUiBlockingTasks((currentKeys) => {
        const newSet = new Set(currentKeys.concat(newKey));
        return Array.from(newSet);
      });
    },
    unblock(oldKey: string) {
      setKeysOfUiBlockingTasks((currentKeys) => {
        const keyIndex = currentKeys.indexOf(oldKey);
        /* istanbul ignore next */
        if (keyIndex > -1) {
          currentKeys = currentKeys.slice();
          currentKeys.splice(keyIndex, 1);
        }
        return currentKeys;
      });
    },
  };
};
