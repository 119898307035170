export enum GridItemTemplate {
  ACCOUNT = 'ACCOUNT',
  ADDRESS = 'ADDRESS',
  APP_LINKS = 'APP_LINKS',
  CHARACTERISTIC_DEFAULT = 'CHARACTERISTIC_DEFAULT',
  CHARACTERISTIC_SMALL = 'CHARACTERISTIC_SMALL',
  DEVICE = 'DEVICE',
  DEVICE_CAROUSEL = 'DEVICE_CAROUSEL',
  INFOTV_SCREENS = 'INFOTV_SCREENS',
  INTERNET_SPEED = 'INTERNET_SPEED',
  MOBILE_PACKAGE_OVERVIEW = 'MOBILE_PACKAGE_OVERVIEW',
  MOBILE_DATA_USAGE = 'MOBILE_DATA_USAGE',
  MOBILE_TOTAL_SERVICE_FEE = 'MOBILE_TOTAL_SERVICE_FEE',
  OVERVIEW = 'OVERVIEW',
  PRIMARY_CHILD_DEFAULT = 'PRIMARY_CHILD_DEFAULT',
  PRIMARY_CHILD_SIM = 'PRIMARY_CHILD_SIM',
  PRIMARY_CHILD_SMALL = 'PRIMARY_CHILD_SMALL',
  ROOT_PRODUCT_PRICE = 'ROOT_PRODUCT_PRICE',
  TOTAL_SERVICE_FEE = 'TOTAL_SERVICE_FEE',
  TV_BASE_PACKAGE = 'TV_BASE_PACKAGE',
  VOIP_GROUP_LOGIN_INFO = 'VOIP_GROUP_LOGIN_INFO',
  VOIP_LOGIN_INFO = 'VOIP_LOGIN_INFO',
}

export enum ExtraCardTemplate {
  SECONDARY_CHILD_DEFAULT = 'SECONDARY_CHILD_DEFAULT',
  SECONDARY_CHILD_IP_ADDRESS = 'SECONDARY_CHILD_IP_ADDRESS',
  SECURE_INTERNET_FILTER = 'SECURE_INTERNET_FILTER',
  CALL_RECORDING = 'CALL_RECORDING',
  TV_THEME_PACKAGE = 'TV_THEME_PACKAGE',
  TV_EXTRA_CHANNEL = 'TV_EXTRA_CHANNEL',
}

export enum AccordionItemTemplate {
  CLOSED_PRODUCT = 'CLOSED_PRODUCT',
  DEVICE_INSURANCE = 'DEVICE_INSURANCE',
  MOBILE_DEVICE_AND_SIM = 'DEVICE_AND_SIM',
  MOBILE_GROUP = 'MOBILE_GROUP',
  MOBILE_NESTED_ACCORDION_ITEM = 'MOBILE_NESTED_ACCORDION_ITEM',
  MOBILE_STANDALONE_NUMBER = 'MOBILE_STANDALONE_NUMBER',
  MOBILE_ROAMING_INFO = 'ROAMING_INFO',
  MOBILE_CALLS_AND_MESSAGES = 'CALLS_AND_MESSAGES',
  MOBILE_ID = 'MOBILE_ID',
  MOBILE_DATA_USAGE_INFO = 'DATA_USAGE_INFO',
  MOBILE_SETTINGS_AND_ADDITIONAL_SERVICES = 'SETTINGS_AND_ADDITIONAL_SERVICES',
  MOBILE_TERMS = 'MOBILE_TERMS',
  PRODUCT_DEFAULT = 'PRODUCT_DEFAULT',
  PRODUCT_DEFAULT_WITH_OVERVIEW = 'PRODUCT_DEFAULT_WITH_OVERVIEW',
  TV = 'TV',
  PRIVATE_BILL = 'PRIVATE_BILL',
  EXTRA_DEVICE_AND_MULTISIM = 'EXTRA_DEVICE_AND_MULTISIM',
}

export enum GenericTemplate {
  DEFAULT = 'DEFAULT',
  NO_DETAILS = 'NO_DETAILS',
  NO_DISPLAY = 'NO_DISPLAY',
  MOBILE_PACKAGE = 'MOBILE_PACKAGE',
}

export type Template = GridItemTemplate | ExtraCardTemplate | AccordionItemTemplate | GenericTemplate;
export const Template = { ...GridItemTemplate, ...ExtraCardTemplate, ...AccordionItemTemplate, ...GenericTemplate };

export function isTemplate(value: string | Template | null): value is Template {
  return !!Object.values(Template).some((template) => template === value);
}
