import { action, computed, IObservableArray, observable, runInAction, makeObservable } from 'mobx';
import ErrorStore from '../error/ErrorStore';
import AddressSuggestionItem from './AddressSuggestionItem';
import StepAddress from './StepAddress';
import Address from '../../model/address/Address';
import ApartmentSuggestionItem from './ApartmentSuggestionItem';
import Room from './Room';
import Location from '../../model/location/Location';
import AddressApi from './AddressApi';

export enum AddressStepId {
  DELIVERY = 'delivery',
  INTERNET_LOCATION = 'internet-location',
}

class AddressStore {
  private readonly buildingIdPrefix: string = '_bid';
  private readonly roomIdPrefix: string = '_rid';

  private addressTimeout: any;
  private apartmentTimeout: any;

  @observable isAddressSubmitted = false;
  @observable addressSuggestions: IObservableArray<AddressSuggestionItem>;
  @observable address: string;
  @observable apartment: string;
  @observable stepAddresses: Map<AddressStepId, StepAddress> = new Map();

  constructor(
    private rootStore: {
      errorStore: ErrorStore;
    }
  ) {
    makeObservable(this);
    this.addressSuggestions = observable.array<AddressSuggestionItem>();
    this.address = '';
    this.apartment = '';
  }

  @computed
  get selectedAddress(): Address | null {
    const address = this.addressSuggestions.find((addressSuggestion) => addressSuggestion.label === this.address);

    if (!address) {
      return null;
    }

    const addressClone = new Address();
    Object.assign(addressClone, address);

    let selectedRoom = addressClone.rooms && addressClone.rooms.find((room) => room.roomName === this.apartment);

    if (!selectedRoom && this.apartment && this.apartment.length > 0) {
      selectedRoom = { roomName: this.apartment };
    }

    addressClone.rooms = !!selectedRoom ? [selectedRoom] : [];

    return addressClone;
  }

  @computed
  get apartmentSuggestions(): ApartmentSuggestionItem[] | undefined {
    const address = this.filterOneAddressSuggestionItemBasedOnAddress();

    if (!address) {
      return [];
    }

    return (
      address.rooms &&
      address.rooms
        .filter((room: Room) => room.roomName && room.roomName.startsWith(this.apartment))
        .sort((left: Room, right: Room) => left.roomName.localeCompare(right.roomName))
        .map((room: Room) => ({
          roomName: room.roomName,
          label: room.roomName,
          url: '#',
          children: null,
        }))
    );
  }

  filterOneAddressSuggestionItemBasedOnAddress(): AddressSuggestionItem {
    return this.addressSuggestions.filter((suggestion) => suggestion.label === this.address).slice(0, 1)[0];
  }

  @action
  handleAddressChange = (inputValue: string, callback?: (location: Location) => void): void => {
    if (inputValue !== this.address) {
      this.apartment = '';
    }
    this.address = inputValue;
    this.isAddressSubmitted = false;

    clearTimeout(this.addressTimeout);
    this.addressTimeout = setTimeout(() => this.findAddressSuggestions(inputValue, callback), 200);
  };

  findAddressSuggestions = (inputValue: string, callback?: (location: Location) => void) => {
    try {
      AddressApi.getAddressSuggestions(encodeURIComponent(inputValue)).then((addresses: Address[]) => {
        runInAction(() =>
          this.addressSuggestions.replace(addresses.map((address) => ({ ...address, url: '#' })) as AddressSuggestionItem[])
        );
        if (callback) {
          const location = this.addressToLocation();
          callback(location);
        }
      });
    } catch (error) {
      error.message = 'Failed to get address suggestions: ' + error.message;
      this.rootStore.errorStore.setGeneralTechnicalError(error);
    }
  };

  async fetchAndPresetAddress(location: Location) {
    const addresses: Address[] = await AddressApi.getAddressSuggestions(encodeURIComponent(location.addrString));
    this.addressSuggestions.replace(addresses.map((address) => ({ ...address, url: '#' })) as AddressSuggestionItem[]);
    if (!!addresses && addresses.length > 0) {
      this.setApartmentValue(location.roomName);
      this.setAddressValue(location.addrString);
    }
  }

  async fetchAndPresetAddressFromPartialLocation(location: Location): Promise<Location | undefined> {
    let inputAddress: string = this.buildingIdPrefix + location.buildingId;
    if (!!location.roomId) {
      inputAddress = this.roomIdPrefix + location.roomId;
    }

    const addresses: Address[] = await AddressApi.getAddressSuggestions(encodeURIComponent(inputAddress));
    if (!!addresses && addresses.length > 0) {
      const actualAddress = addresses.find((address) => address.buildingId === location.buildingId);
      if (actualAddress) {
        this.setAddressValue(actualAddress.label);
      }
      this.setApartmentValue(location.roomName);
      return this.addressToLocation(actualAddress, location.roomName || undefined);
    }
    return undefined;
  }

  @action
  handleApartmentChange = (inputValue: string, callback?: (location: Location) => void): void => {
    this.apartment = inputValue;
    this.isAddressSubmitted = false;

    clearTimeout(this.apartmentTimeout);
    this.apartmentTimeout = setTimeout(() => {
      if (callback) {
        const location = this.addressToLocation();
        callback(location);
      }
    }, 1000);
  };

  addressToLocation = (filteredAddress?: Address, roomName?: string): Location => {
    const address: Address = !!filteredAddress ? filteredAddress : this.filterOneAddressSuggestionItemBasedOnAddress();
    const location = new Location();
    if (address) {
      let room;
      if (address.rooms && !!address.rooms.length) {
        room = address.rooms.find((filteredRoom) => filteredRoom.roomName === (!!roomName ? roomName : this.apartment));
      }

      Object.assign(location, {
        adrId: !!room ? room.roomAddressId : address.id,
        adrIdType: address.addressType,
        region: address.region,
        cityCounty: address.cityCounty,
        municipality: address.municipality,
        postalCode: address.postalCode,
        addrString: address.label,

        addInformation: address.addInformation,
        buildingId: address.buildingId,
        buildingName: address.buildingName,
        buildingSubtype: address.buildingType,
        coordX: address.buildingCoordX,

        coordY: address.buildingCoordY,
        roomName: !!room ? room.roomName : this.apartment,
        roomId: !!room ? room.roomId : null,
        floor: !!room ? room.entrance : null,
        entrance: !!room ? room.entrance : null,
        roomType: !!room ? room.roomType : null,
        bgNum: !!room ? room.roomHouse : address.house,
        streetBgName: !!room ? room.roomStreet : address.street,
      });
    }
    return location;
  };

  @action setAddressValue = (value: string) => (this.address = value);

  @action setApartmentValue = (value: string) => (this.apartment = value);

  @action clearAddressSuggestions = () => this.addressSuggestions.replace([]);

  @computed
  get isAddressFound(): boolean {
    return this.address.length === 0 || this.selectedAddress !== null;
  }

  @computed
  get isAddressValid(): boolean {
    return this.address.length !== 0;
  }

  @computed
  get isApartmentValid(): boolean {
    return !!this.apartmentSuggestions && (this.apartmentSuggestions.length === 0 || this.apartment.length !== 0);
  }

  @computed
  get isFullValidAddressSelected(): boolean {
    return this.isAddressFound && this.isAddressValid && this.isApartmentValid;
  }

  @action
  setStepAddress = (stepId: AddressStepId) => {
    const stepAddress: StepAddress = {
      address: this.address,
      apartment: this.apartment,
    };
    this.stepAddresses.set(stepId, stepAddress);
  };

  @action getStepAddress = (stepId: AddressStepId): StepAddress | undefined => this.stepAddresses.get(stepId);

  @action
  deleteStepAddress = (stepId: AddressStepId) => {
    this.stepAddresses.delete(stepId);
  };

  applyStepAddress = (stepId: AddressStepId) => {
    const stepAddress = this.getStepAddress(stepId);
    if (!!stepAddress) {
      this.setAddressValue(stepAddress.address);
      this.setApartmentValue(stepAddress.apartment);
    }
  };

  @action
  handleAddressSubmit() {
    this.isAddressSubmitted = this.isFullValidAddressSelected;
  }

  @action
  reset() {
    this.addressSuggestions.clear();
    this.address = '';
    this.apartment = '';
    this.isAddressSubmitted = false;
    this.stepAddresses.clear();
  }
}

export default AddressStore;
