export enum ApiUrl {
  PRODUCT_DETAILS = '/myse-frontend/api/v1/products/{productId}',
  TOTAL_SERVICE_CHARGE = '/myse-frontend/api/v1/products/{productId}/charges',
  TOTAL_SERVICE_CHARGE_USAGES = '/myse-frontend/api/v1/products/charges/usages/{usageRefNumber}',
  PRODUCT_USERS = '/myse-frontend/api/v1/products/product-users',
  PRODUCT_USER_BY_PRODUCT_ID = '/myse-frontend/api/v1/products/{productId}/product-users',
  PRODUCT_USER = '/myse-frontend/api/v1/products/{productId}/product-users/mobile-number/{mobileNumber}',
  PRODUCT_USER_UPDATE_STATUS = '/myse-frontend/api/v1/products/product-users/poll',
  ORDER_STATUSES = '/myse-frontend/api/v1/products/mobile/{productId}/product-orders',
  CHANGE_MOBILE_GROUP_NAME = '/myse-frontend/api/v1/products/mobile/change-mobile-group-name',
  INDEXED_PRODUCTS = '/myse-frontend/api/v1/products/indexed-products',
  UPDATE_ALIAS = '/myse-frontend/api/v1/products/{productGlobalId}/alias',
  CUSTOMER_ACCESS = '/myse-frontend/api/v1/customer-access',
  AGREEMENTS = '/myse-frontend/api/v1/agreements',
  AGREEMENTS_BY_AGREEMENT_ITEM_IDS = '/myse-frontend/api/v1/agreements/product-agreements',
  PRODUCT_BY_AGREEMENT = '/myse-frontend/api/v1/agreements/{agreementId}/product',
  DASHBOARD_TRANSFER_CUSTOMER = '/myse-frontend/api/v1/dashboard/transfer-customer',
  DASHBOARD_QUICK_LINKS = '/myse-frontend/api/v1/dashboard/quick-links',
  DASHBOARD_QUICK_LINKS_OVERVIEW = '/myse-frontend/api/v1/dashboard/quick-links/count',
  DASHBOARD_GROUPED_OVERVIEWS = '/myse-frontend/api/v1/dashboard/grouped-overviews/{groupingType}',
  DASHBOARD_PRIVACY_NOTICE = '/myse-frontend/api/v1/dashboard/privacy-notice',
  UPDATE_CUSTOM_SOLUTION_AGREEMENT_NUMBER = '/myse-frontend/api/v1/products/{productId}/update-agreement-by-type',
  POSITIONING_LOCATION = '/myse-frontend/api/v1/mobile-app/positioning/locations',
  POSITIONING_PERMISSIONS_FOR_ME = '/myse-frontend/api/v1/mobile-app/positioning/permissions/for-me',
  POSITIONING_PERMISSIONS_FOR_OTHERS = '/myse-frontend/api/v1/mobile-app/positioning/permissions/for-others',
  POSITIONING_PERMISSIONS_DELETE = '/myse-frontend/api/v1/mobile-app/positioning/permissions/{id}',
  POSITIONING_PERMISSIONS_ASK = '/myse-frontend/api/v1/mobile-app/positioning/permissions',
  POSITIONING_PERMISSIONS_ACCEPT = '/myse-frontend/api/v1/mobile-app/positioning/permissions/{id}/accept',
  BILLING_OVERVIEW = '/myse-frontend/api/v1/dashboard/billing-overview',
  DASHBOARD_NOTIFICATIONS = '/myse-frontend/api/v1/dashboard/notifications',
  DASHBOARD_NOTIFICATIONS_CLICK = '/myse-frontend/api/v1/dashboard/notifications/{id}/markAsClicked',
  SHOW_NEW_DETAILS = '/myse-frontend/api/v1/configs/show-new-details',
  PRODUCT_DETAILS_DATA = '/myse-frontend/api/v1/products/{globalProductId}/product-details/{type}',
  PRODUCT_DETAILS_OVERVIEW_DATA = '/myse-frontend/api/v1/products/{globalProductId}/product-details/product_details_overview',
  PRODUCT_DETAILS_NOTICES_DATA = '/myse-frontend/api/v1/products/{globalProductId}/product-details/product_details_notices',
  PRODUCT_DETAILS_MODAL_DATA = '/myse-frontend/api/v1/products/{globalProductId}/product-details/product_details_modal',
  FEATURE_FLAGS = '/myse-frontend/api/v1/feature-flags/ui',
  PRODUCT_SUMMARIES = '/myse-frontend/api/v1/products',
  PRODUCTS_LISTS = '/myse-frontend/api/v1/products/lists',
  PRODUCT_LISTS = '/myse-frontend/api/v1/products/lists/{listName}/items',
  TERMINATE_FINANCE_AGREEMENT = '/myse-frontend/api/v1/products/{productGlobalId}/terminate',
  FINANCE_AGREEMENT_TERMINATION_CONTENT = '/myse-frontend/api/v1/products/{globalProductId}/product-details/{type}',
  PAYMENT_PLAN = 'myse-frontend/api/v1/products/{globalProductId}/product-details/payment-plan',
  PAYMENT_PLAN_PDF = 'myse-frontend/api/v1/agreements/finance/documents/elis/{productId}/download/schedule',
  LIST_FILTER_REFNUMS_DATA = '/myse-frontend/api/v1/products/filter/refNums',
  PRODUCT_ADDRESS_SEARCH = '/myse-frontend/api/v1/products/filter/product-locations',

  // TODO: when new list pages are implemented these will not be needed anymore since notifications should come with different approuch
  CREATE_BASKET = '/order-capture-frontend/api/catalogapi/baskets',
  CUSTOMER_PRODUCT_OFFERINGS = '/order-capture-frontend/api/catalogapi/product-offerings/customers/{customerId}',

  FLOW_ALARM = '/order-capture-frontend/api/flow-team-alarm',

  QUERY_PRODUCT_RECOMMENDATIONS = '/polc-frontend/api/query-product-recommendations',
  DASHBOARD_CUSTOMER_MARKET_SEGMENT_INSTANCES_V2 = '/polc-frontend/api/market-segments/customer-instances/V2',
  EVALUATE_PROMOTIONS = '/polc-frontend/api/coupon-promotion/{promotionName}/benefits/evaluate',

  HAS_DEBT_BY_CUSTOMER = '/sales-api/debt/customers/{customerId}/has-debt2',
  ACKNOWLEDGED_ORDERS = '/order-frontend/api/v1/customer-orders/count',

  TV_CHANNELS = '/sales-api/tv/channels',
  TERMS_ACCEPT = '/sales-api/customer-products/terms/accept',
  TERMS_CONSENT = '/sales-api/customer-products/terms/consent',
  CUSTOMER_PRESALE_API = '/sales-api/customer-presale-api',
  TRANSLATIONS = '/sales-api/translations',
}
