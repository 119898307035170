import { action, observable, runInAction, makeObservable } from 'mobx';

export const FULL_PAGE = 'fullPage';
export const PROGRESS_ITEM = 'progressItem';
export const PACKAGE_COMPARISON = 'comparePackages';
export const MYTECHNICIAN = 'myTechnician';
export const LAST_TELIA_RECOMMENDATION = 'lastTeliaRecommendation';

export class SpinnerStore {
  @observable spinnerCache: Map<string, Spinner[]> = new Map();

  constructor() {
    makeObservable(this);
  }

  isVisible(name: string): boolean {
    return this.spinnerCache.get(name) ? !!this.spinnerCache.get(name)!.length : false;
  }

  @action
  show(spinnerName: string) {
    let spinners = this.spinnerCache.get(spinnerName);
    const spinner = new Spinner();

    if (spinners) {
      spinners.unshift(spinner);
    } else {
      spinners = [spinner];
    }

    this.spinnerCache.set(spinnerName, spinners);
  }

  @action
  hide(spinnerName: string) {
    const spinners = this.spinnerCache.get(spinnerName);

    if (!spinners) {
      return;
    }

    runInAction(() => {
      spinners.pop();
      this.spinnerCache.set(spinnerName, spinners);
    });
  }
}

class Spinner {}

export default SpinnerStore;
