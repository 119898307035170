/* istanbul ignore file */
import { CoreConfig } from './CoreConfig';

const grafanaRumScript = () =>
  CoreConfig.environment !== 'local' &&
  `TeliaRum.init({
    beaconUrl: '${CoreConfig.teliaOtelWebConfig!.beaconUrl}',
    clientId: '${CoreConfig.teliaOtelWebConfig!.clientId}',
    clientToken: '${CoreConfig.teliaOtelWebConfig!.clientToken}',
    ignoreUrls: [
      /https:\\/\\/cat.telia.ee\\.*/,
      /https:\\/\\/ccchat.estpak.ee\\/.*/,
      /https:\\/\\/www.google-analytics.com\\/.*/,
      /https:\\/\\/widgets.getsitecontrol.com\\/.*/,
      /https:\\/\\/googleads.g.doubleclick.net\\/.*/,
      /https:\\/\\/www.googletagmanager.com\\/.*/,
      /https:\\/\\/www.facebook.com\\/.*/,
      /\\.*.cookiebot.com\\.*/,
    ],
    propagateCorsUrls: [
      /https:\\/\\/sso(-arendus|-dev2|-test)?\\.telia\\.ee(\\/.*)?/,
      /https:\\/\\/iseteenindus(-dev|-test)?\\.telia\\.ee(\\/.*)?/,
    ],
    domain: '.telia.ee'
  });`;

const injectScript = (scriptInnerHtml: string) => {
  const script = document.createElement('script');
  script.innerHTML = scriptInnerHtml;
  document.head.appendChild(script);
};

export const setGrafanaRum = () => {
  const agentScript = document.createElement('script');
  agentScript.src = CoreConfig.teliaOtelWebConfig!.rumAgent;
  document.head.appendChild(agentScript);
  agentScript.addEventListener('load', () => {
    injectScript(grafanaRumScript() || '');
  });
};
