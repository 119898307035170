export enum RoutePathPattern {
  DASHBOARD = '/:lang(et|ru|en)?/',
  HOME_PRODUCTS = '/:lang(et|ru|en)?/teenused/kodu',
  OFFICE_PRODUCTS = '/:lang(et|ru|en)?/teenused/kontor',
  VAS_PRODUCTS = '/:lang(et|ru|en)?/teenused/lisateenused',
  MOBILE_PRODUCTS = '/:lang(et|ru|en)?/teenused/mobiil',
  APP_COOKIE_CONSENT = '/:lang(et|ru|en)?/teenused/api-kupsised',
  POSITIONING = '/:lang(et|ru|en)?/teenused/positsioneerimine',
  MANAGE_POSITIONING_PERMISSIONS = '/:lang(et|ru|en)?/teenused/positsioneerimine/halda-positsioneeritavaid',
  POSITIONING_TERMS = '/:lang(et|ru|en)?/teenused/positsioneerimine/tingimused',
  TV_PRODUCTS = '/:lang(et|ru|en)?/teenused/tv-ja-striiming',
  INTERNET_PRODUCTS = '/:lang(et|ru|en)?/teenused/koduinternet',
  USER_RENTAL_DEVICES = '/:lang(et|ru|en)?/teenused/seadmed',
  PRODUCT_DETAILS = '/:lang(et|ru|en)?/teenused/:productIdAndOrigin([1-9]\\d*_[A-Z_]+)',
  PRODUCT_FLOW = '/:lang(et|ru|en)?/tellimine/:productIdAndOrigin([1-9]\\d*_[A-Z_]+)',
  CHANGE_CUSTOMER_PRODUCT = '/:lang(et|ru|en)?/tellimine/loovutamine-kinnita/:productId([1-9]\\d*)',
  CANCEL_CHANGE_CUSTOMER = '/:lang(et|ru|en)?/teenused/loovutamise-tyhistamine',
  MOBILE_SELECTION = '/:lang(et|ru|en)?/teenused/mobiili-valik',
  PRODUCT_SELECTION = '/:lang(et|ru|en)?/teenused/toote-valik',
  NUMBER_PAIRING = '/:lang(et|ru|en)?/teenused/numbri-sidumine',
  MOBILE_PAYMENTS = '/:lang(et|ru|en)?/mobiil/maksed-mobiiliga/:msisdn',
  PICK_AND_MIX = '/:lang(et|ru|en)?/mobiil/lisasoodustus',
  AGREEMENTS = '/:lang(et|ru|en)?/lepingud',
  SERVICE_AGREEMENTS = '/:lang(et|ru|en)?/lepingud/teenuste-lepingud',
  DETAILS_VIEW_TEST = '/:lang(et|ru|en)?/teenused/detailid-test/:productIdAndOrigin([1-9]\\d*_[A-Z_]+)',
  DETAILS_VIEW_OLD = '/:lang(et|ru|en)?/teenused/detailid-vana/:productIdAndOrigin([1-9]\\d*_[A-Z_]+)',
  ACCOUNT = '/:lang(et|ru|en)?/konto',
  ALL_SERVICES = '/:lang(et|ru|en)?/teenused/koik-teenused',
  FINANCE_PRODUCTS = '/:lang(et|ru|en)?/teenused/finantseerimine',
  FINANCE_AGREEMENT_TERMINATION = '/:lang(et|ru|en)?/teenused/finantseerimine/lopetamine',
}
