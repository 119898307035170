import { observable, makeObservable, computed, reaction, comparer } from 'mobx';
import SsoStore from '../sso/SsoStore';
import ErrorStore from '../error/ErrorStore';
import { Api } from '../../service/Api';
import RestrictionType from '../../model/customer/customerAccess/RestrictionType';
import CustomerAccess from '../../model/customer/customerAccess/CustomerAccess';
import AccessSubject from '../../model/customer/customerAccess/AccessSubject';
import CustomerAccessSubject from '../../model/customer/customerAccess/CustomerAccessSubject';
import Permission from '../../model/customer/customerAccess/Permission';

export interface CustomerAccessStoreContext {
  ssoStore: SsoStore;
  errorStore: ErrorStore;
}

export default class CustomerAccessStore {
  @observable readonly customerAccessRequest = Api.customerAccess();

  constructor(private readonly context: CustomerAccessStoreContext) {
    makeObservable(this);
    const { ssoStore } = this.context;
    reaction(() => ssoStore.customer, this.updateCustomerAccess, {
      name: 'get person when customer selected  -> update customer access',
      equals: comparer.structural,
    });
  }

  async init(): Promise<void> {
    if (this.context.ssoStore.customer) {
      await this.updateCustomerAccess();
    }
  }

  @computed
  get isLoadingDone(): boolean {
    const { isLoaded } = this.customerAccessRequest;
    return isLoaded;
  }

  @computed
  get customerAccess(): CustomerAccess | undefined {
    const { data } = this.customerAccessRequest;

    if (!this.isLoadingDone || !data) {
      return undefined;
    }
    return data;
  }

  @computed
  get hasViewCustomerServiceAgreementsAccess(): boolean {
    const permission = this.getPermissionsBySubject(AccessSubject.VIEW_CUSTOMER_SERVICE_AGREEMENTS);
    return !!permission?.hasAccess;
  }

  @computed
  get isViewCustomerServiceAgreementsAccessRestrictedByAccounts(): boolean {
    const permission = this.getPermissionsBySubject(AccessSubject.VIEW_CUSTOMER_SERVICE_AGREEMENTS);
    return !!permission?.restrictions.includes(RestrictionType.RESTRICTED_BY_ACCOUNTS);
  }

  @computed
  get hasViewCustomerOrFinancialAgreementsAccess(): boolean {
    const permission = this.getPermissionsBySubject(AccessSubject.VIEW_CUSTOMER_OR_FINANCIAL_AGREEMENTS);
    return !!permission?.hasAccess;
  }

  @computed
  get hasViewCustomerProductsAccess(): boolean {
    const permission = this.getPermissionsBySubject(AccessSubject.VIEW_CUSTOMER_PRODUCTS);
    return !!permission?.hasAccess;
  }

  @computed
  get isViewCustomerProductsRestrictedByProducts(): boolean {
    const permission = this.getPermissionsBySubject(AccessSubject.VIEW_CUSTOMER_PRODUCTS);
    return !!permission?.restrictions?.includes(RestrictionType.RESTRICTED_BY_PRODUCTS);
  }

  @computed
  get hasBillingOverviewAccess(): boolean {
    const permission = this.getPermissionsBySubject(AccessSubject.BILL_OVERVIEW);
    return !!permission?.hasAccess;
  }

  @computed
  get hasQuickLinksAccess(): boolean {
    const permission = this.getPermissionsBySubject(AccessSubject.DASHBOARD_QUICK_LINKS);
    return !!permission?.hasAccess;
  }

  @computed
  get hasCustomerSegmentAccess(): boolean {
    const permission = this.getPermissionsBySubject(AccessSubject.CUSTOMER_SEGMENT);
    return !!permission?.hasAccess;
  }

  private updateCustomerAccess = async (): Promise<void> => {
    this.customerAccessRequest.clear();
    await this.customerAccessRequest.load();

    if (this.customerAccessRequest.isErrored) {
      const { setGeneralTechnicalError } = this.context.errorStore;
      setGeneralTechnicalError(new Error('Customer access fetch failed'));
    }
  };

  private getPermissionsBySubject(subject: CustomerAccessSubject): Permission | undefined {
    return this.customerAccess?.permissions[subject];
  }
}
