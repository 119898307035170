import { AnalyticsTrigger, Button as TeliaReactButton, IButtonProps } from 'telia-front-react';
import React from 'react';

export type ButtonProps = {
  gaLabel?: string;
} & IButtonProps;

export const Button = ({ gaLabel, ...rest }: ButtonProps) =>
  gaLabel ? (
    <AnalyticsTrigger
      trigger={{
        callback: 'onClick',
        id: `Link - ${gaLabel}`,
      }}
    >
      <TeliaReactButton {...rest} />
    </AnalyticsTrigger>
  ) : (
    <TeliaReactButton {...rest} />
  );
