import { observable, makeObservable, computed } from 'mobx';
import ErrorStore from '../error/ErrorStore';
import { Api } from '../../service/Api';
import { AgreementResource } from '../../model/agreement/AgreementResource';
import { AgreementsHolderResource } from '../../model/agreement/AgreementsHolderResource';

export interface AgreementStoreContext {
  errorStore: ErrorStore;
}

export interface LoadAgreementsParams {
  size: number;
  page: number | null;
  searchTerm?: string;
  agreementStates: string[] | null;
  startAfter: Date | null;
  startBefore: Date | null;
}

export default class AgreementStore {
  @observable readonly agreementRequest = Api.agreements();

  constructor(private readonly context: AgreementStoreContext) {
    makeObservable(this);
  }

  @computed
  get agreements(): AgreementResource[] | undefined {
    const { isLoaded, data } = this.agreementRequest;

    if (!isLoaded || !data) {
      return undefined;
    }
    return data.agreements;
  }

  loadAgreements = async (params: LoadAgreementsParams): Promise<AgreementsHolderResource | undefined> => {
    this.agreementRequest.clear();
    const agreementsResponse = await this.agreementRequest.load({ params });

    if (this.agreementRequest.isErrored) {
      const { setGeneralTechnicalError } = this.context.errorStore;
      setGeneralTechnicalError(new Error('Agreements fetch failed'));
    }

    return agreementsResponse.data;
  };
}
