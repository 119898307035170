import React, { useContext } from 'react';
import { TechnicalError } from './TechnicalError';
import { observer } from 'mobx-react';
import RootStoreContext from '../../shared/store/root/RootStoreContext';

export const GeneralTechnicalError = observer(() => {
  const { errorStore } = useContext(RootStoreContext);
  const { message, category } = errorStore.generalTechnicalError;
  return <TechnicalError message={message} category={category} />;
});
