/* istanbul ignore file */

enum AccessSubject {
  DASHBOARD_QUICK_LINKS = 'DASHBOARD_QUICK_LINKS',
  BILL_OVERVIEW = 'BILL_OVERVIEW',
  CUSTOMER_SEGMENT = 'CUSTOMER_SEGMENT',
  VIEW_CUSTOMER_PRODUCTS = 'VIEW_CUSTOMER_PRODUCTS',
  VIEW_USER_PRODUCTS = 'VIEW_USER_PRODUCTS',
  CHECK_DEBT = 'CHECK_DEBT',
  ORDER_PRODUCTS = 'ORDER_PRODUCTS',
  PRODUCT_FEES = 'PRODUCT_FEES',
  SERVICE_USAGE_DISTINCTION = 'SERVICE_USAGE_DISTINCTION',
  VIEW_CUSTOMER_SERVICE_AGREEMENTS = 'VIEW_CUSTOMER_SERVICE_AGREEMENTS',
  VIEW_USER_SERVICE_AGREEMENTS = 'VIEW_USER_SERVICE_AGREEMENTS',
  VIEW_CUSTOMER_OR_FINANCIAL_AGREEMENTS = 'VIEW_CUSTOMER_OR_FINANCIAL_AGREEMENTS',
}

export default AccessSubject;
