export enum RouteKey {
  SECURE_INTERNET = 'secureInternet',
  HOME_PRODUCTS = 'homeServices',
  INTERNET_PRODUCTS = 'internetServices',
  OFFICE_PRODUCTS = 'officeServices',
  VAS_PRODUCTS = 'vasServices',
  MOBILE_PRODUCT = 'mobileService',
  MOBILE_PRODUCTS = 'mobileServices',
  MOBILE_ADDITIONAL_SERVICES = 'mobileAdditionalServices',
  NOT_FOUND = 'notFound',
  UNDEFINED = 'undefinedRoute',
  DASHBOARD = 'dashboard',
}

export default RouteKey;
