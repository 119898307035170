/* istanbul ignore next */
export default class Location {
  adrId: string;
  adrIdType: string;
  region: string;
  cityCounty: string;
  municipality: string;
  streetBgName: string;
  bgNum: string | null;
  postalCode: string;
  addrString: string;
  buildingId: string;
  buildingName: string;
  buildingSubtype: string;
  coordX: string;
  coordY: string;
  roomName: string;
  roomId: string | null;
  floor: string | null;
  entrance: string | null;
  roomType: string | null;
  addInformation: string;
}
